import $ from "jquery";

// Load Styles
import '../../scss/landing-vesting/main.scss';
import '../../scss/landing-vesting/fonts/GothamPro-Medium.woff';


import {initBootstrap} from "../bootstrap";

initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false
});

import Web3Component, {from} from './wallet'

const web3Component = new window.web3Component(window.configCrypt)

function showCancelSeed() {
  const modal = document.querySelector('.blur-portal__seed-cancel')
  modal.classList.add('active', 'animate')
}

function showSuccessBuy() {
  const modalSuccess = document.querySelector('.blur-portal__success-buy')
  modalSuccess.classList.add('active', 'animate')
}

function hideSuccessBuy() {
  const modalSuccess = document.querySelector('.blur-portal__success-buy')
  modalSuccess.classList.remove('active', 'animate')
}

function showErrorBuy() {
  const modalSuccess = document.querySelector('.blur-portal__error-buy')
  modalSuccess.classList.add('active', 'animate')
}

function hideErrorBuy() {
  const modalSuccess = document.querySelector('.blur-portal__error-buy')
  modalSuccess.classList.remove('active', 'animate')
}


function showLoader() {
  const loader = document.querySelector('.blur-portal__loader')
  loader.classList.add('active', 'animate')
}

function hideLoader() {
  const loader = document.querySelector('.blur-portal__loader')
  loader.classList.remove('active', 'animate')
}

window.modalSeedCancel = showCancelSeed;
window.modalSuccessBuy = showSuccessBuy;
window.buyTest = buyTest;

const daysText = document.querySelector('#days');
const hoursText = document.querySelector('#hours');
const minutesText = document.querySelector('#minutes');


// -----------------------------------
//              Seed not started
//------------------------------------

function showNotStartedSeed() {
  const modalNotStarted = document.querySelector('.blur-portal__private-not-started')
  modalNotStarted.classList.add('active', 'animate')
}

function hideNotStartedSeed() {
  const modalNotStarted = document.querySelector('.blur-portal__private-not-started')
  modalNotStarted.classList.remove('active', 'animate')
}

async function checkStartSeed() {
  const seedStart = await web3Component.getPublicVariable('seedStart')
  const seedStartFormatted = new Date(parseInt(seedStart) * 1000).getTime();
  const currentTime = Date.now();

  const buySeedButton = document.querySelector('#buy-btn-seed');


  if (seedStartFormatted > currentTime) {
    showNotStartedSeed()
  } else {
    buySeedButton.classList.remove('d-none');
  }

}
checkStartSeed();

const modal = document.querySelector('.blur-portal__seed-cancel')

let x = setInterval(async function () {

  let now = Date.now();

  const seedEnd = await web3Component.getPublicVariable('seedEnd')

  const endTime = new Date(parseInt(seedEnd) * 1000).getTime();


  if (endTime) {

    let distance = endTime - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));


    if (days < 10) {
      days = `0${days}`
    }

    if (hours < 10) {
      hours = `0${hours}`
    }

    if (minutes < 10) {
      minutes = `0${minutes}`
    }


    daysText.innerHTML = `${days}`;
    hoursText.innerHTML = `${hours}`;
    minutesText.innerHTML = `${minutes}`;

    if (distance < 0) {
      buySeedButton.classList.add('d-none');
      clearInterval(x);
      daysText.innerHTML = `00`;
      hoursText.innerHTML = `00`;
      minutesText.innerHTML = `00`;
      modal.classList.add('active', 'animate')
    }

  }


}, 1000);

const toastAutoShow = async (type, text) => {
  const toastHolder = document.querySelector('.no-auto-toast');
  if (toastHolder) return;
  const toast = $(".toast");
  const toastHtml = document.querySelector('.toast')
  const toastText = toastHtml.querySelector('.text')
  toastHtml.classList.add(`toast__${type}`)
  toastText.innerHTML = text;
  setTimeout(() => toast.show(), 100);
  const delayTimeout = +toast.data("delay");
  setTimeout(() => {
    toast.hide()
    toastHtml.classList.remove(`toast__${type}`);
  }, 4000);

}

// -----------------------------------
//              Web3Component
//------------------------------------

const buySeedButton = document.querySelector('#buy-btn-seed');
const connectButton = document.querySelector('#wallet-connect');
const connectButtonText = document.querySelector('#wallet-connect-text');
const connectButtonMobile = document.querySelector('#wallet-connect-mobile');
const disconnectButton = document.querySelector('#wallet-disconnect');
const disconnectButtonMobile = document.querySelector('#wallet-disconnect-mobile');
const repeatButton = document.querySelector('#repeat-pay');
const buyTestButton = document.querySelector('#buyTest');
const mobileNavButton = document.querySelector('#mobile-nav');
const successModalCloseButton = document.querySelector('.modal__close');
const errorModalCloseButton = document.querySelector('.modal__close-error');

const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

window.onload = async (event) => {

  const account = await web3Component.getAccount();

  if (account) {
    connectButtonText.innerHTML = `Wallet: ${account}`;
    connectButtonMobile.innerHTML = `Wallet: ${account}`;
    connectButtonText.title = `Wallet: ${account}`;
  }

  const remainingSeedCount = await web3Component.getPublicVariable('remainingSeedCount');

  const remainingSeedSpan = document.querySelector('#remaining_count')
  if (remainingSeedSpan) {
    remainingSeedSpan.innerHTML = remainingSeedCount;
  }
};

function disconnectWallet() {
  console.log('disconnect')
  web3Component.disconnectWallet();

  connectButtonText.innerHTML = `WalletConnect`;
  connectButtonText.title = `WalletConnect`;
  connectButtonMobile.innerHTML = 'WalletConnect';

  disconnectButton.classList.add('d-none');

  if (vw <= 992) {
    connectButton.classList.remove('d-none');
    mobileNavButton.classList.add('d-none')
  }

}

async function connectWallet() {

  try {
    await web3Component.connectWallet().then(() => {
      toastAutoShow('success', 'Кошелёк подключен')
      const account = web3Component.getAccount()
      connectButtonText.title = `Wallet: ${account}`;

      let value = '...' + account;
      do {
        value = '...' + value.substring(4);
        connectButtonMobile.innerHTML = `Wallet: <br/> ${value}`;
      } while (connectButtonMobile.scrollWidth > connectButtonMobile.offsetWidth);

      do {
        value = '...' + value.substring(4);
        connectButtonText.innerHTML = `Wallet: ${value}`;
      } while (connectButtonText.scrollWidth > connectButtonText.offsetWidth);

      disconnectButton.classList.remove('d-none');

      if(vw <= 992) {
        disconnectButton.classList.add('d-none');
        connectButton.classList.add('d-none');

        mobileNavButton.classList.remove('d-none');
      }

      const chainId = web3Component.getProvider().chainId;
      console.log(chainId);
      if(chainId !== 56) {
        console.log('Wrong chain, please choose BSC');
      } else {
        console.log('Account', account);
      }
      web3Component.getProvider().on("chainChanged", (chainId) => {
        if(chainId !== 56) {
          console.log('Wrong chain, please choose BSC chain', account);
        } else {
          console.log('Account', account);
        }
      });
    })

  } catch (e) {
    toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
    console.error(e);
  }
}

async function buyTest() {

  try {

    const provider = await web3Component.getProvider();

    if (!provider || !provider.connected) {
      /*disconnectWallet();*/
      await connectWallet();
    }
    showLoader();

    console.log('start pay provider', provider, localStorage)

      showLoader();
      let buy = await web3Component.buyTest().then((data) => {
        console.log(data);
        console.log('Транзакция прошла: https://testnet.bscscan.com/tx/'+data);
        hideLoader();
        showSuccessBuy();
      }).catch((err) => {
        console.log('Error', err);
        hideLoader()
        if(err.transactionHash) {
          console.log('Транзакция не прошла: https://testnet.bscscan.com/tx/'+err.transactionHash);
          toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
        } else if (err.message.startsWith('Error: User rejected the transaction')) {
          toastAutoShow('error', 'Операция отменена пользователем')
        } else {
          toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
        }
      }).finally(() => {
        /*disconnectWallet();*/
      })

      hideLoader()

      new Promise((resolve, reject) => {
        resolve(buy)
        setTimeout(() => {
          console.log('TimeOut')
          /*disconnectWallet();*/
          hideLoader()
          showErrorBuy()
          reject();

        }, 3000000)
      });

  } catch (e) {
    /*disconnectWallet();*/
    console.error(e);
    hideLoader()
    toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
  }
}

async function buySeed() {
  try {

    const provider = await web3Component.getProvider();

    if (!provider || !provider.connected) {
      /*await web3Component.disconnectWallet();*/
      await connectWallet();
    }

    console.log('start pay provider', provider, localStorage)

      showLoader();
      let buy = await web3Component.buySeed().then((data) => {
        console.log(data);
        console.log('Транзакция прошла: https://testnet.bscscan.com/tx/'+data);
        hideLoader()
        showSuccessBuy();
      }).catch((err) => {
        console.log('Error', err);
        hideLoader()
        if(err.transactionHash) {
          console.log('Транзакция не прошла: https://testnet.bscscan.com/tx/'+err.transactionHash);
          toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
        } else if (err.message.startsWith('Error: User rejected the transaction')) {
          toastAutoShow('error', 'Операция отменена пользователем')
        } else {
          toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
        }
      }).finally(() => {
        /*disconnectWallet();*/
      })

      hideLoader()

      new Promise((resolve, reject) => {
        resolve(buy)
        setTimeout(() => {
          console.log('TimeOut')
          /*disconnectWallet();*/
          hideLoader()
          showErrorBuy()
          reject();

        }, 3000000)
      });

  } catch (e) {
    /*disconnectWallet();*/
    console.error(e);
    hideLoader()
    toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
  }
}


connectButton.addEventListener('click', connectWallet);
connectButtonMobile.addEventListener('click', connectWallet);

disconnectButton.addEventListener('click', disconnectWallet);
disconnectButtonMobile.addEventListener('click', disconnectWallet);

successModalCloseButton.addEventListener('click', hideSuccessBuy);

if (buySeedButton) {
  buySeedButton.addEventListener('click', buySeed);
}
repeatButton.addEventListener('click', function () {
  hideErrorBuy()
  buySeed()
})
errorModalCloseButton.addEventListener('click', hideErrorBuy);

buyTestButton.addEventListener('click', buyTest);